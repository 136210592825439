import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 2%;
    border: solid 1px #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #eee;
    border: 0;
    box-shadow: 0 0 5px #777;
    max-width: 1200px;
    span.area{
        font-weight: 900;
        text-transform: uppercase;
        font-size: 20px;
    }

    p{
        color: #777;
    }

    .containerUserData{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .username{
            font-weight: 900;
            font-size: 18px;
            color: #111;
        }
    }

    .containerBtn{
        width: 100%;
        button{
            width: 100%;
            border: 0;
            padding: 10px;
            background-color: #000;
            color: #fff;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: ease-in-out .4s;
        }
        button:hover{
            
        background-color: hsl(352, 69%, 51%);
        }
    }

`