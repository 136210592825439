import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .containerClientList{
        width: 100%;        
        display: flex;
        @media screen and (max-width:768px){
            width: 100%;
            flex-direction: column;
        }
        .containerAside{
            flex: 2;
            height: 100%;
            border-right: 1px solid #ccc;
        }
        .containerList{
            flex: 8;
            @media screen and (max-width:1380px){
                flex: 5;
            }
            
            @media screen and (max-width:1000px){
                flex: 3;
            }
        }
    }
`